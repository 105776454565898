import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { parseToRgb, rgb } from 'polished';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { validateColor } from '~/layouts/utils';
import defaultTheme from '~/themes/theme.json';
export const ThemeContext = createContext({});
export function ThemeProvider({ children }) {
    const { data: initialData } = useInitialData();
    const [colors, setColors] = useState(defaultTheme.colors);
    const calculateOffset = (color1, color2) => {
        const rgb1 = parseToRgb(color1);
        const rgb2 = parseToRgb(color2);
        return {
            r: rgb2.red - rgb1.red,
            g: rgb2.green - rgb1.green,
            b: rgb2.blue - rgb1.blue,
        };
    };
    const applyOffset = (color, offset) => {
        const rgbColor = parseToRgb(color);
        const newColor = {
            r: Math.min(255, Math.max(0, rgbColor.red + offset.r)),
            g: Math.min(255, Math.max(0, rgbColor.green + offset.g)),
            b: Math.min(255, Math.max(0, rgbColor.blue + offset.b)),
        };
        return rgb(newColor.r, newColor.g, newColor.b);
    };
    const initialOffset = calculateOffset(defaultTheme.colors.purple900, defaultTheme.colors.purple700);
    const setPurple900 = (color) => {
        if (!validateColor(color)) {
            return;
        }
        const newPurple700 = applyOffset(color, initialOffset);
        setColors((prevColors) => (Object.assign(Object.assign({}, prevColors), { purple900: color, purple700: newPurple700 })));
    };
    const setPink500 = (color) => {
        setColors((prevColors) => (Object.assign(Object.assign({}, prevColors), { pink500: color })));
    };
    useEffect(() => {
        if ((initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color) ||
            (initialData === null || initialData === void 0 ? void 0 : initialData.theme.secondary_color)) {
            setColors((prevColors) => {
                const updatedPurple900 = validateColor(initialData.theme.primary_color)
                    ? initialData.theme.primary_color
                    : prevColors.purple900;
                const newPurple700 = applyOffset(updatedPurple900, initialOffset);
                const updatedPink500 = validateColor(initialData.theme.secondary_color)
                    ? initialData.theme.secondary_color
                    : prevColors.pink500;
                return Object.assign(Object.assign({}, prevColors), { purple900: updatedPurple900, purple700: newPurple700, pink500: updatedPink500 });
            });
        }
    }, [initialData]);
    const theme = useMemo(() => ({
        colors,
    }), [colors]);
    const contextValue = useMemo(() => ({
        setPurple900,
        setPink500,
    }), [setPurple900, setPink500]);
    return (_jsx(ThemeContext.Provider, Object.assign({ value: contextValue }, { children: _jsx(StyledThemeProvider, Object.assign({ theme: theme }, { children: children })) })));
}
