import styled from 'styled-components';
export const Header = styled.div `
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.purple900};
  padding: 20px 40px;
  box-sizing: border-box;
`;
export const Image = styled.img `
  width: 178px;
  height: 42x;
  cursor: pointer;
`;
export const Navbar = styled.nav `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NavMenu = styled.ul `
  display: flex;
  list-style: none;
  gap: 24px;
`;
export const NavMenuItem = styled.li ``;
