var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, ContentWrapper, Group, Text, } from '@gameficame/ui-kit/src/components';
import { Skeleton } from '@mantine/core';
const PurchaseAndPoints = (_a) => {
    var { points, isLoading } = _a, rest = __rest(_a, ["points", "isLoading"]);
    return (_jsx(Box, Object.assign({ p: "20px", direction: "column", gap: "24px" }, rest, { children: isLoading ? (_jsx(Skeleton, { w: "100%", h: "82px" })) : (_jsx(ContentWrapper, Object.assign({ direction: "row", justify: "space-between", align: "center", p: "15px 20px" }, { children: _jsx(Group, Object.assign({ w: "100%", justify: "center", py: "10px" }, { children: _jsxs(Text, Object.assign({ fontColor: "purple700", fontSize: "32px", lineHeight: "32px" }, { children: [points, " pts"] })) })) }))) })));
};
export default PurchaseAndPoints;
