var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Flex, Group, Input, ItemForm, NumberInput, } from '@gameficame/ui-kit/src/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
const Points = (_a) => {
    var { onClickPublishPrizePoints, onClickBack } = _a, rest = __rest(_a, ["onClickPublishPrizePoints", "onClickBack"]);
    const { t } = useTranslation();
    const [showSecondForm, setShowSecondForm] = useState(false);
    const addNewPrizePointsSchema = () => {
        return Yup.object().shape({
            name: Yup.string(),
            stock: Yup.number(),
        });
    };
    const formik = useFormik({
        initialValues: {
            name: '',
            stock: undefined,
        },
        validateOnMount: true,
        validationSchema: addNewPrizePointsSchema,
        onSubmit: () => {
            onClickPublishPrizePoints(formik.values);
        },
    });
    const getErrorMsg = (field) => {
        return formik.touched[field] ? formik.errors[field] : undefined;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ItemForm, Object.assign({ title: t('prizeFormSelector.form.points.title'), isFinished: showSecondForm }, rest, { children: [_jsx(Input, { id: "name", onChange: (e) => formik.handleChange(e), error: getErrorMsg('name'), onBlur: formik.handleBlur, value: formik.values.name, label: t('prizeFormSelector.form.points.input.label'), placeholder: t('prizeFormSelector.form.points.input.placeholder').toString() }), _jsx(Flex, Object.assign({ justify: "flex-end" }, { children: _jsxs(Group, Object.assign({ w: "340px", justify: "flex-end" }, { children: [_jsx(Button, Object.assign({ variant: "secondary", w: "160px", onClick: onClickBack }, { children: t('prizeFormSelector.button.back') })), _jsx(Button, Object.assign({ w: "160px", onClick: () => setShowSecondForm(true) }, { children: t('prizeFormSelector.button.continue') }))] })) }))] })), showSecondForm ? (_jsxs(ItemForm, Object.assign({ title: t('prizeFormSelector.form.stock.title'), description: t('prizeFormSelector.form.stock.subtitle').toString(), isFinished: formik.isSubmitting }, { children: [_jsx(Checkbox, { background: "black700", label: t('prizeFormSelector.form.stock.checkbox.label') }), _jsx(NumberInput, { id: "stock", onChange: (e) => formik.setFieldValue('stock', e), error: getErrorMsg('stock'), onBlur: formik.handleBlur, value: formik.values.stock, label: t('prizeFormSelector.form.stock.input.label'), placeholder: "200", allowNegative: false, decimalSeparator: ",", thousandSeparator: "." }), _jsx(Flex, Object.assign({ justify: "flex-end" }, { children: _jsxs(Group, Object.assign({ w: "340px", justify: "flex-end" }, { children: [_jsx(Button, Object.assign({ variant: "secondary", w: "160px", onClick: () => setShowSecondForm(false) }, { children: t('prizeFormSelector.button.back') })), _jsx(Button, Object.assign({ w: "160px", onClick: () => formik.handleSubmit() }, { children: t('prizeFormSelector.button.publish') }))] })) }))] }))) : null] }));
};
export default Points;
