import styled from 'styled-components';
export const ItemContainer = styled.div `
  cursor: pointer;
  width: 158px;
  min-height: 175px;
  border-radius: 5px;
  border: 1px solid
    ${({ selected, theme }) => selected ? theme.colors.purple700 : 'transparent'};
  display: flex;
  align-self: stretch;
`;
