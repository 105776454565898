import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Flex } from '@gameficame/ui-kit/src/components';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import * as Styled from './styles';
const DefaultLayout = ({ children }) => {
    return (_jsxs(Styled.Container, { children: [_jsx(Header, {}), _jsxs(Flex, { children: [_jsx(Sidebar, {}), _jsx(Styled.Body, { children: children })] })] }));
};
export default DefaultLayout;
