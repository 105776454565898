import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Badge } from '@gameficame/ui-kit/src/components';
function BadgeStatus({ type }) {
    const text = () => {
        switch (type) {
            case 'yellow':
                return 'GERENTE';
            case 'red':
                return 'ADMINISTRADOR';
            case 'lightBlue':
                return 'JOGADOR';
            default:
                return 'ADMINISTRADOR';
        }
    };
    return (_jsx(Badge, Object.assign({ "data-testid": "badge-status", badgeColor: type }, { children: text() })));
}
export default BadgeStatus;
