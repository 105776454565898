var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import Cookies from 'js-cookie';
import { refreshTokens } from '~/layouts/contexts/AuthProvider/TokenStorageService';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
export const apiWithAuthorization = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
apiWithAuthorization.interceptors.request.use((config) => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});
apiWithAuthorization.interceptors.response.use((response) => response, (error) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const originalRequest = error.config;
    if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const newAccessToken = yield refreshTokens();
            if (newAccessToken) {
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return axios(originalRequest);
            }
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
    return Promise.reject(error);
}));
export const apiWithoutAuthorization = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
export default apiWithAuthorization;
