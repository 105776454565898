var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@gameficame/ui-kit/src/components';
import logoWhite from '~/assets/logo-white.svg';
import { useAuth } from '~/layouts/hooks';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { imageDomain } from '~/layouts/utils';
import * as Styled from './styles';
function Header(_a) {
    var rest = __rest(_a, []);
    const { data: initialData } = useInitialData();
    const { logout } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onClickLogout = () => __awaiter(this, void 0, void 0, function* () {
        if (logout) {
            logout();
        }
    });
    return (_jsxs(Styled.Header, Object.assign({}, rest, { children: [_jsx(Styled.Image, { src: (initialData === null || initialData === void 0 ? void 0 : initialData.theme.menu_image.url)
                    ? imageDomain + initialData.theme.menu_image.url
                    : logoWhite, onClick: () => navigate('/admin/panel') }), _jsx(Styled.NavMenu, { children: _jsx(Styled.NavMenuItem, { children: _jsx(Text, Object.assign({ "data-testid": "logout", fontSize: "14px", weight: "700", lineHeight: "22px", fontColor: "white100", onClick: onClickLogout }, { children: t('header.button.logout') })) }) })] })));
}
export default Header;
