import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import enLanguage from './Translations/en-US.json';
import ptLanguage from './Translations/pt-BR.json';
/*  As mensagens, presentes na pasta Translations, devem ter seu próprio arquivo com suas próprias traduções,
    mas o código referente a cada frase deve ser o mesmo em diferentes arquivos. */
// Buscando lingua do navegador
const language = () => {
    switch (navigator.language) {
        case 'pt-BR':
            return 'pt-BR';
        default:
            return 'en-US';
    }
};
// Usando linguagem do navegador para definir o idioma do sistema
i18n.use(initReactI18next).init({
    fallbackLng: language,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        'en-US': enLanguage,
        'pt-BR': ptLanguage,
    },
});
export default i18n;
