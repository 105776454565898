import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge } from '@gameficame/ui-kit/src/components';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { lightenColor } from '~/layouts/utils';
import * as Styled from './styles';
export const SidebarButton = ({ to, title, subBtn, handleClick, }) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const location = useLocation();
    const { data: initialData } = useInitialData();
    const handleOpenSubMenu = () => {
        if (subBtn !== undefined) {
            setOpenSubMenu(!openSubMenu);
        }
    };
    return (_jsxs("li", { children: [subBtn === undefined ? (_jsx(Styled.SidebarButton, Object.assign({ to: { pathname: to }, onClick: handleClick, active: location.pathname === to ? 'true' : 'false' }, { children: title }))) : (_jsx(Styled.NavBtn, Object.assign({ onClick: () => {
                    handleOpenSubMenu();
                } }, { children: title }))), _jsx(Styled.SubMenu, Object.assign({ opened: openSubMenu.toString(), tall: subBtn !== undefined ? subBtn.length : 0 }, { children: subBtn !== undefined
                    ? subBtn.map((btn) => (_jsx(Styled.SubLink, Object.assign({ to: btn.to, onClick: handleClick, active: location.pathname === btn.to ? 'true' : 'false', disabled: btn.soon }, { children: _jsxs("span", { children: [btn.title, ' ', btn.soon ? (_jsx(Badge, Object.assign({ weight: "400", style: {
                                        backgroundColor: lightenColor(initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color, 0.25) || '#D5CAF2',
                                        color: '#000000',
                                    } }, { children: "EM BREVE" }))) : null] }) }), btn.title)))
                    : null }))] }));
};
