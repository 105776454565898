import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PrivateRouteContainer } from '~/layouts/containers/PrivateRouteContainer';
import { useInitialData } from '~/layouts/hooks/useInitialData';
function RouteWrapper({ component: Component, isProtectedLayout = false, }) {
    const location = useLocation();
    const { data } = useInitialData();
    const pagesTitle = {
        '/admin': 'Login',
        '/admin/forgot-password': 'Recuperar conta',
        '/admin/panel': 'Painel',
        '/admin/item-shop': 'Loja de itens',
        '/admin/item-shop/new-item': 'Novo item',
        '/admin/users': 'Usuários',
        '/admin/daily-rewards': 'Recompensas diárias',
        '/admin/daily-rewards/new': 'Nova recompensa diária',
        '/admin/new-password': 'Nova senha',
        '/admin/reset-password': 'Mudar senha',
        '/admin/stores': 'Lojas',
        '/admin/stores/new-store': 'Nova loja',
        '/admin/new-prize': 'Novo prêmio',
        '/admin/vouchers': 'Vouchers',
        '/admin/vouchers/new-voucher': 'Novo Voucher',
        '/admin/add-points': 'Pontuar Manualmente',
        '/admin/exchange': 'Efetuar Trocas',
        '/admin/campaigns': 'Campanhas',
        '/admin/campaigns/new-campaign': 'Nova Campanha',
        '/admin/theme': 'Temas',
        '/admin/raffle': 'Sorteios',
        '/admin/new-raffle': 'Novo Sorteio',
        '/admin/raffle/check-winner': 'Vencedor do Sorteio',
        '/admin/raffle/edit/:id': 'Editar Sorteio',
        '/admin/member': 'Convide Amigos',
        '/admin/member/new-member': 'Novo Convide Amigos',
        '/admin/users/:id': 'Perfil do Usuário',
        '/admin/users/edit/:id': 'Editar Usuário',
        '/admin/item-shop/edit-item/:id': 'Editar Item',
        '/admin/stores/:id': 'Editar Loja',
        '/admin/vouchers/add-voucher/:id': 'Adicionar Voucher',
        '/admin/campaigns/edit-campaign/:id': 'Editar Campanha',
        '/admin/member/edit/:id': 'Editar Convide Amigos',
        '/admin/item-stock/:id': 'Estoque do Item',
    };
    const club = `${data === null || data === void 0 ? void 0 : data.account_name} | `;
    function getPageTitle(pathname) {
        const matchedRoute = Object.keys(pagesTitle).find((route) => {
            const routeRegex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`);
            return routeRegex.test(pathname);
        });
        return matchedRoute ? pagesTitle[matchedRoute] : '';
    }
    const title = club
        ? `${club + getPageTitle(location.pathname)}`
        : getPageTitle(location.pathname);
    useEffect(() => {
        document.title = title;
    }, [club, location]);
    if (isProtectedLayout) {
        return (_jsx(PrivateRouteContainer, Object.assign({ redirectTo: "/admin" }, { children: _jsx(Component, {}) })));
    }
    return _jsx(Component, {});
}
export default RouteWrapper;
