import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Avatar, Box, Stack, Text } from '@gameficame/ui-kit/src/components';
import { formatSSNorCPF } from '~/layouts/utils';
import { UserBadge } from '../Userbadge';
function UserDetails({ name, email, phone, cpf, city, badge, }) {
    const type = () => {
        switch (badge) {
            case 'MANAGER':
                return 'yellow';
            case 'ADMIN':
                return 'red';
            case 'PLAYER':
                return 'lightBlue';
            default:
                return 'lightBlue';
        }
    };
    return (_jsx(Box, Object.assign({ p: "20px", direction: "column", gap: "24px" }, { children: _jsxs(Stack, Object.assign({ align: "center", gap: "8px" }, { children: [_jsx(Avatar, { size: "80px", radius: "50%" }), _jsx(UserBadge, { type: type() }), _jsx(Text, Object.assign({ fontColor: "purple700", fontSize: "18px", lineHeight: "28px" }, { children: name })), _jsx(Text, Object.assign({ fontColor: "grey500", fontSize: "14px", lineHeight: "22px", weight: "300" }, { children: email })), phone ? (_jsx(Text, Object.assign({ fontColor: "grey500", fontSize: "14px", lineHeight: "22px", weight: "300" }, { children: phone }))) : null, phone && (cpf || city) ? (_jsx(Text, Object.assign({ fontColor: "grey500", fontSize: "14px", lineHeight: "22px", weight: "300" }, { children: "-" }))) : null, cpf ? (_jsx(Text, Object.assign({ fontColor: "grey500", fontSize: "14px", lineHeight: "22px", weight: "300" }, { children: formatSSNorCPF(cpf) }))) : null, city ? (_jsx(Text, Object.assign({ fontColor: "grey500", fontSize: "14px", lineHeight: "22px", weight: "300" }, { children: city }))) : null] })) })));
}
export default UserDetails;
