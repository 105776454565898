import styled from 'styled-components';
import container from '~/layouts/styles/container';
import BasePage from '~/pages/BasePage';
export const Container = styled(BasePage) `
  ${container}
  min-height: 100vh;
  width: 100%;
`;
export const Body = styled.div `
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
`;
