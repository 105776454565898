import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Box, ContentWrapper, Group, Stack, Text, } from '@gameficame/ui-kit/src/components';
import boost from '~/assets/buttons/disabled-lightning.svg';
import products from '~/assets/buttons/hand.svg';
import points from '~/assets/buttons/points.svg';
import raffle from '~/assets/buttons/roulette.svg';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { lightenColor } from '~/layouts/utils';
import { Boost, Points, Products } from './PrizesTypesForms';
import * as Styled from './styles';
function PrizeTypeFormSelector({ selectedPrizes, productsData, handlePublishProducts, isPublishing, isError, onCreateAndPublishProduct, }) {
    const { t } = useTranslation();
    const [type, setType] = useState('products');
    const { data: initialData } = useInitialData();
    const prizesList = [
        {
            id: 'points',
            label: t('prizeFormSelector.types.icon.points'),
            icon: points,
        },
        {
            id: 'products',
            label: t('prizeFormSelector.types.icon.products'),
            icon: products,
        },
        {
            id: 'boost',
            label: t('prizeFormSelector.types.icon.boost'),
            icon: boost,
        },
        {
            id: 'raffle',
            label: t('prizeFormSelector.types.icon.raffle'),
            icon: raffle,
        },
    ];
    const handleClickBack = () => {
        setType(undefined);
    };
    return (_jsxs(Stack, { children: [_jsxs(Box, Object.assign({ direction: "column", p: "16px", gap: "8px" }, { children: [_jsx(Text, Object.assign({ fontSize: "18px", lineHeight: "28px", fontColor: "purple700" }, { children: t('prizeFormSelector.types.title') })), _jsx(Text, Object.assign({ weight: "300", fontSize: "16px", lineHeight: "26px", fontColor: "grey500" }, { children: t('prizeFormSelector.types.subtitle') })), _jsx(Group, Object.assign({ gap: "8px", grow: true, align: "stretch" }, { children: prizesList
                            .filter((data) => selectedPrizes.includes(data.id))
                            .map((data) => (_jsxs(ContentWrapper, Object.assign({ "data-testid": data.id, justify: "center", align: "center", direction: "column", gap: "8px", p: "8px", onClick: () => {
                                if (data.id === 'products')
                                    setType(data.id);
                            }, style: {
                                cursor: data.id === 'products' ? 'pointer' : 'default',
                            }, selected: type === data.id }, { children: [_jsx(Styled.Icons, { src: data.icon }), _jsx(Text, Object.assign({ weight: "300", fontSize: "14px", fontColor: "black600" }, { children: data.label })), data.id !== 'products' ? (_jsx(Badge, Object.assign({ weight: "400", style: {
                                        backgroundColor: lightenColor(initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color, 0.75) ||
                                            '#D5CAF2',
                                        color: '#000000',
                                    } }, { children: "EM BREVE" }))) : null] }), data.id))) }))] })), type === 'points' ? (_jsx(Points, { "data-testid": "points-form", onClickPublishPrizePoints: (values) => console.log(values), onClickBack: handleClickBack })) : null, type === 'products' ? (_jsx(Products, { "data-testid": "products-form", productsData: productsData, onClickPublishPrizeProducts: handlePublishProducts, onCreateAndPublishProduct: onCreateAndPublishProduct, onClickBack: handleClickBack, isPublishing: isPublishing, isError: isError })) : null, type === 'boost' ? (_jsx(Boost, { "data-testid": "boost-form", onClickPublishPrizeBoost: (values) => console.log(values), onClickBack: handleClickBack })) : null] }));
}
export default PrizeTypeFormSelector;
