import { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ThemeContext } from '~/layouts/contexts';
import api from '~/services/api';
const useTheme = () => {
    const context = useContext(ThemeContext);
    return context;
};
export const useUpdateThemes = () => {
    const updateThemes = useMutation({
        mutationFn: (colors) => {
            return api.post(`/themes`, colors);
        },
    });
    return updateThemes;
};
export default useTheme;
