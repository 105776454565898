import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
export const SidebarBody = styled.div `
  width: 250px;
  min-height: 100vh;
  overflow: hidden;
  flex-grow: 1;
  background: ${({ theme }) => theme.colors.purple900};
`;
export const LinkList = styled.ul `
  position: relative;
  list-style: none;
  padding: 0;
  display: block;
  transition: all 0.5s ease;
  gap: 20px;
`;
export const SidebarButton = styled(NavLink) `
  position: relative;
  color: ${({ active, theme }) => active === 'true' ? theme.colors.pink500 : theme.colors.white};
  font-weight: bold;
  padding: 18px 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  margin: 2px 10px;

  &:hover {
    background: #ffffff15;
  }
`;
export const NavBtn = styled.div `
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  padding: 18px 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  margin: 2px 10px;
  cursor: pointer;
  &:hover {
    background: #ffffff15;
  }
`;
export const SubMenu = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: ${({ opened, tall }) => (opened === 'true' ? tall * 50 : 0)}px;
  transition: all 0.4s ease-in-out;
`;
// active === 'true' because the console of navigator was alerting: Received `true` for non-boolean attribute `active`. If this is expected, cast the value to a string.
export const SubLink = styled(NavLink) `
  color: ${({ active, theme }) => active === 'true' ? theme.colors.pink500 : theme.colors.white};
  padding: 8px 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 2px 10px;
  border-radius: 10px;

  > span {
    margin-left: 14px;
    word-break: break-all;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.pink500};
    transition: all 0.2s ease;
  }

  ${({ disabled }) => disabled &&
    `
    color: ${({ theme }) => theme.colors.grey500};
    cursor: default;
    pointer-events: none;
  `}
`;
